const tab_sections = {
    overview: 'Übersicht',
    resource_management: 'Ressourcenverwaltung',
    user_management: 'Benutzerverwaltung',
    access_control: 'Zugriffskontrolle',
    help_and_support: 'Hilfe und Support',
    tenant: 'Mieter',
    automation: 'Automatisierung',
};
export default tab_sections;
