import { z } from 'zod';
export var TenantTag;
(function (TenantTag) {
    TenantTag["Development"] = "development";
    TenantTag["Staging"] = "staging";
    TenantTag["Production"] = "production";
})(TenantTag || (TenantTag = {}));
export const createTenantGuard = z.object({
    id: z.string(),
    dbUser: z.string(),
    dbUserPassword: z.string(),
    name: z.string().optional(),
    tag: z.nativeEnum(TenantTag).optional(),
    createdAt: z.number().optional(),
});
export const tenantInfoGuard = createTenantGuard
    .pick({ id: true, name: true, tag: true })
    .extend({ indicator: z.string() })
    .required();
