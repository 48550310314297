const tab_sections = {
    overview: '概要',
    resource_management: 'リソース管理',
    user_management: 'ユーザー管理',
    access_control: 'アクセス制御',
    help_and_support: 'ヘルプとサポート',
    tenant: 'テナント',
    automation: 'オートメーション',
};
export default tab_sections;
