const users = {
    page_title: '用戶管理',
    title: '用戶管理',
    subtitle: '管理你的用戶，包括創建新用戶，編輯用戶資料，查看用戶日誌，以及重新設置密碼和刪除用戶',
    create: '添加用戶',
    create_subtitle: '至少提供以下其中一個字段以繼續進行。',
    error_missing_identifier: '你必須提供至少一個註冊標識來創建用戶。',
    user_name: '用戶',
    application_name: '註冊應用',
    latest_sign_in: '最後登錄',
    create_form_username: '用戶名',
    create_form_password: '密碼',
    create_form_name: '姓名',
    placeholder_name: '無名氏',
    placeholder_email: 'moumingsi@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+852 9123 4567',
    unnamed: '未命名',
    search: '按姓名、電子郵件、電話或用戶名搜索',
    check_user_detail: '查看用戶詳情',
    placeholder_title: '用戶管理',
    placeholder_description: '每個用戶都有一個包含所有用戶信息的個人資料。它由基本數據、社交身份和自定義數據組成。',
};
export default users;
