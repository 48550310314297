const tab_sections = {
    overview: 'Overview',
    resource_management: 'Resource Management',
    user_management: 'User Management',
    access_control: 'Access Control',
    help_and_support: 'Help and Support',
    tenant: 'Tenant',
    automation: 'Automation',
};
export default tab_sections;
