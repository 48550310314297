const tab_sections = {
    overview: 'Обзор',
    resource_management: 'Управление ресурсами',
    user_management: 'Управление пользователями',
    access_control: 'Управление доступом',
    help_and_support: 'Помощь и поддержка',
    tenant: 'Арендатор',
    automation: 'Автоматизация',
};
export default tab_sections;
