const users = {
    page_title: '用户管理',
    title: '用户管理',
    subtitle: '管理你的用户，包括创建新用户，编辑用户资料，查看用户日志，以及重新设置密码和删除用户',
    create: '添加用户',
    create_subtitle: '提供以下至少一项字段才能继续。',
    error_missing_identifier: '你必须提供至少一个注册标识来创建用户。',
    user_name: '用户',
    application_name: '注册应用',
    latest_sign_in: '最后登录',
    create_form_username: '用户名',
    create_form_password: '密码',
    create_form_name: '姓名',
    placeholder_name: '李雷',
    placeholder_email: 'lilei@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+86 123 4567 8901',
    unnamed: '未命名',
    search: '按姓名、电子邮件、电话或用户名搜索',
    check_user_detail: '查看用户详情',
    placeholder_title: '用户管理',
    placeholder_description: '每个用户都有一个包含所有用户信息的个人资料。它由基本数据、社交身份和自定义数据组成。',
};
export default users;
