const tab_sections = {
    overview: 'Przegląd',
    resource_management: 'Zarządzanie zasobami',
    user_management: 'Zarządzanie użytkownikami',
    access_control: 'Kontrola dostępu',
    help_and_support: 'Pomoc i wsparcie',
    tenant: 'Najemca',
    automation: 'Automatyzacja',
};
export default tab_sections;
