const tabs = {
    get_started: 'Primeiros passos',
    dashboard: 'Painel',
    applications: 'Aplicativos',
    api_resources: 'Recursos da API',
    sign_in_experience: 'Experiência de login',
    connectors: 'Conectores',
    webhooks: 'Webhooks',
    users: 'Usuários',
    audit_logs: 'Logs',
    roles: 'Registros',
    docs: 'Documentação',
    contact_us: 'Contate-nos',
    tenant_settings: 'Configurações',
};
export default tabs;
