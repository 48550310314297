const tab_sections = {
    overview: 'Genel Bakış',
    resource_management: 'Kaynak Yönetimi',
    user_management: 'Kullanıcı Yönetimi',
    access_control: 'Erişim Kontrolü',
    help_and_support: 'Yardım ve Destek',
    tenant: 'Kiracı',
    automation: 'Otomasyon',
};
export default tab_sections;
