const tab_sections = {
    overview: '概览',
    resource_management: '资源管理',
    user_management: '用户管理',
    access_control: '访问控制',
    help_and_support: '帮助与支持',
    tenant: '租户',
    automation: '自动化',
};
export default tab_sections;
