const tab_sections = {
    overview: 'Panoramica',
    resource_management: 'Gestione delle Risorse',
    user_management: 'Gestione Utenti',
    access_control: 'Controllo Accessi',
    help_and_support: 'Aiuto e Supporto',
    tenant: 'Locatario',
    automation: 'Automazione',
};
export default tab_sections;
