const tab_sections = {
    overview: '살펴보기',
    resource_management: '리소스 관리',
    user_management: '사용자 관리',
    access_control: '접근 제어',
    help_and_support: '고객센터',
    tenant: '세입자',
    automation: '자동화',
};
export default tab_sections;
