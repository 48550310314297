const tabs = {
    get_started: 'Başla',
    dashboard: 'Gösterge Paneli',
    applications: 'Uygulamalar',
    api_resources: 'API Kaynakları',
    sign_in_experience: 'Oturum Açma Deneyimi',
    connectors: 'Connectors',
    webhooks: 'Webhooks',
    users: 'Kullanıcı Yönetimi',
    audit_logs: 'Denetim Kayıtları',
    roles: 'Roller',
    docs: 'Dökümanlar',
    contact_us: 'Bize Ulaşın',
    tenant_settings: 'Ayarlar',
};
export default tabs;
