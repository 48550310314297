const roles = {
    page_title: 'Rôles',
    title: 'Rôles',
    subtitle: "Les rôles incluent des autorisations qui déterminent ce qu'un utilisateur peut faire. RBAC utilise des rôles pour donner aux utilisateurs accès à des ressources pour des actions spécifiques.",
    create: 'Créer un rôle',
    role_name: 'Rôle',
    role_description: 'Description',
    role_name_placeholder: 'Entrez le nom de votre rôle',
    role_description_placeholder: 'Entrez la description de votre rôle',
    assigned_users: 'Utilisateurs assignés',
    assign_permissions: 'Assigner des autorisations',
    create_role_title: 'Créer un rôle',
    create_role_description: "Créez et gérez des rôles pour vos applications. Les rôles contiennent des collections d'autorisations et peuvent être assignés aux utilisateurs.",
    create_role_button: 'Créer un rôle',
    role_created: 'Le rôle {{name}} a été créé avec succès.',
    search: 'Rechercher par nom de rôle, description ou ID',
    placeholder_title: 'Rôles',
    placeholder_description: "Les rôles sont un regroupement d'autorisations qui peuvent être assignées aux utilisateurs. Assurez-vous d'ajouter d'abord des autorisations avant de créer des rôles.",
};
export default roles;
