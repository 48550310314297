import { Domains } from '../db-entries/index.js';
export const domainSelectFields = Object.freeze([
    'id',
    'domain',
    'status',
    'errorMessage',
    'dnsRecords',
]);
export const domainResponseGuard = Domains.guard.pick({
    id: true,
    domain: true,
    status: true,
    errorMessage: true,
    dnsRecords: true,
});
export var DomainStatus;
(function (DomainStatus) {
    DomainStatus["PendingVerification"] = "PendingVerification";
    DomainStatus["PendingSsl"] = "PendingSsl";
    DomainStatus["Active"] = "Active";
    DomainStatus["Error"] = "Error";
})(DomainStatus || (DomainStatus = {}));
