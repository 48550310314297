const tab_sections = {
    overview: 'Vista geral',
    resource_management: 'Gestão de recursos',
    user_management: 'Gestão de utilizadores',
    access_control: 'Controlo de acesso',
    help_and_support: 'Ajuda e suporte',
    tenant: 'Arrendatário',
    automation: 'Automação',
};
export default tab_sections;
