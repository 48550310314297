const tab_sections = {
    overview: '概觀',
    resource_management: '資源管理',
    user_management: '用戶管理',
    access_control: '訪問控制',
    help_and_support: '幫助與支持',
    tenant: '租戶',
    automation: '自動化',
};
export default tab_sections;
