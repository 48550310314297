const tabs = {
    get_started: '開始上手',
    dashboard: '儀表板',
    applications: '全部應用',
    api_resources: 'API 資源',
    sign_in_experience: '登入體驗',
    connectors: '連接器',
    webhooks: 'Webhooks',
    users: '使用者管理',
    audit_logs: '稽核日誌',
    roles: '角色',
    docs: '文件',
    contact_us: '聯絡我們',
    tenant_settings: '設定',
};
export default tabs;
