const log_details = {
    page_title: 'Szczegóły dziennika audytu',
    back_to_logs: 'Powróć do dziennika audytu',
    back_to: 'Powróć do {{name}}',
    success: 'Sukces',
    failed: 'Niepowodzenie',
    event_key: 'Klucz zdarzenia',
    application: 'Aplikacja',
    ip_address: 'Adres IP',
    user: 'Użytkownik',
    log_id: 'ID dziennika',
    time: 'Czas',
    user_agent: 'Użytkownik agent',
    tab_details: 'Szczegóły',
    raw_data: 'Surowe dane',
};
export default log_details;
