const tab_sections = {
    overview: "Vue d'ensemble",
    resource_management: 'Gestion des ressources',
    user_management: 'Gestion des utilisateurs',
    access_control: "Contrôle d'accès",
    help_and_support: 'Aide et support',
    tenant: 'Locataire',
    automation: 'Automatisation',
};
export default tab_sections;
