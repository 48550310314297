const tabs = {
    get_started: '시작하기',
    dashboard: '대시보드',
    applications: '어플리케이션',
    api_resources: 'API 리소스',
    sign_in_experience: '로그인 경험',
    connectors: '연동',
    webhooks: '웹훅',
    users: '사용자 관리',
    audit_logs: '감사 기록',
    roles: '역할',
    docs: '문서',
    contact_us: '연락처',
    tenant_settings: '설정',
};
export default tabs;
