const users = {
    page_title: 'Gestão de utilizadores',
    title: 'Gestão de utilizadores',
    subtitle: 'Gerencie os utilizadores, incluindo a criação, edição de informações, visualização de logs, recuperações de password e exclusões',
    create: 'Adicionar usuário',
    create_subtitle: 'Fornecer pelo menos um dos seguintes campos para proceder.',
    error_missing_identifier: 'Tem de fornecer pelo menos um identificador para criar um utilizador.',
    user_name: 'Utilizador',
    application_name: 'Do aplicativo',
    latest_sign_in: 'Último login',
    create_form_username: 'Utilizador',
    create_form_password: 'Palavra-passe',
    create_form_name: 'Nome completo',
    placeholder_name: 'Zé Ninguém',
    placeholder_email: 'zninguem@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+351 21 234 5678',
    unnamed: 'Sem nome',
    search: 'Procurar por nome, email, telefone ou nome de utilizador',
    check_user_detail: 'Ver detalhes do utilizador',
    placeholder_title: 'Gestão de utilizadores',
    placeholder_description: 'Cada utilizador tem um perfil contendo todas as informações do utilizador. Consiste em dados básicos, identidades sociais e dados personalizados',
};
export default users;
