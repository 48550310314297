const users = {
    page_title: 'Gestione utenti',
    title: 'Gestione utenti',
    subtitle: "Gestisci le identità degli utenti, inclusa la creazione di utenti, la modifica delle informazioni degli utenti, la visualizzazione dei log degli utenti, il ripristino delle password e l'eliminazione degli utenti",
    create: 'Aggiungi utente',
    create_subtitle: 'Fornire almeno uno dei seguenti campi per procedere.',
    error_missing_identifier: 'Devi fornire almeno un identificatore per creare un utente.',
    user_name: 'Utente',
    application_name: "Dall'applicazione",
    latest_sign_in: 'Ultimo accesso',
    create_form_username: 'Nome utente',
    create_form_password: 'Password',
    create_form_name: 'Nome completo',
    placeholder_name: 'Mario Rossi',
    placeholder_email: 'mario@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+39 02 1234567',
    unnamed: 'Senza nome',
    search: 'Cerca per nome, email, telefono o nome utente',
    check_user_detail: "Controlla i dettagli dell'utente",
    placeholder_title: 'Gestione utenti',
    placeholder_description: "Ogni utente ha un profilo contenente tutte le informazioni dell'utente. È composto da dati di base, identità sociali e dati personalizzati.",
};
export default users;
