const log_details = {
    page_title: 'Audit Log details',
    back_to_logs: 'Back to Audit Logs',
    back_to: 'Back to {{name}}',
    success: 'Success',
    failed: 'Failed',
    event_key: 'Event Key',
    application: 'Application',
    ip_address: 'IP address',
    user: 'User',
    log_id: 'Log ID',
    time: 'Time',
    user_agent: 'User agent',
    tab_details: 'Details',
    raw_data: 'Raw data',
};
export default log_details;
