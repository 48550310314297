// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { domainDnsRecordsGuard, cloudflareDataGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    domain: z.string().min(1).max(256),
    status: z.string().min(1).max(32).optional(),
    errorMessage: z.string().max(1024).nullable().optional(),
    dnsRecords: domainDnsRecordsGuard.optional(),
    cloudflareData: cloudflareDataGuard.nullable().optional(),
    updatedAt: z.number().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    domain: z.string().min(1).max(256),
    status: z.string().max(32),
    errorMessage: z.string().max(1024).nullable(),
    dnsRecords: domainDnsRecordsGuard,
    cloudflareData: cloudflareDataGuard.nullable(),
    updatedAt: z.number(),
    createdAt: z.number(),
});
export const Domains = Object.freeze({
    table: 'domains',
    tableSingular: 'domain',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        domain: 'domain',
        status: 'status',
        errorMessage: 'error_message',
        dnsRecords: 'dns_records',
        cloudflareData: 'cloudflare_data',
        updatedAt: 'updated_at',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'domain',
        'status',
        'errorMessage',
        'dnsRecords',
        'cloudflareData',
        'updatedAt',
        'createdAt',
    ],
    createGuard,
    guard,
});
