const tabs = {
    get_started: 'Empezar',
    dashboard: 'Tablero',
    applications: 'Aplicaciones',
    api_resources: 'Recursos de API',
    sign_in_experience: 'Experiencia de inicio de sesión',
    connectors: 'Conectores',
    webhooks: 'Webhooks',
    users: 'Gestión de usuarios',
    audit_logs: 'Registros de auditoría',
    roles: 'Roles',
    docs: 'Documentos',
    contact_us: 'Contáctanos',
    tenant_settings: 'Configuraciones',
};
export default tabs;
